@import url('https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400;500;600;700&display=swap');

body {
  font-family: 'Urbanist';
  background-color: #F4F4F3;
}

html {
  scroll-behavior: smooth;
  background-color: #F4F4F3;
}
/* Reset default margins and padding */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Set background color for entire viewport */
:root {
  background-color: white;
}

html {
  scroll-behavior: smooth;
  background-color: white;
  min-height: 100%;
  overflow-x: hidden;
}

body {
  font-family: 'Urbanist';
  background-color: white;
  min-height: 100vh;
  /* Add padding for iOS notch */
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
}

/* Hide scrollbar for Chrome, Safari, and Opera */
.md-hidden::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge, and Firefox */
.md-hidden {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}


.gradient-box {
  position: relative; 
  box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.105);
  border-radius: 70px; 
  overflow: hidden;
}

.gradient-box::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top right, #FCDBC1, #FACCC6, #FBE9DA, #F4F4F3); /* The gradient background */
  opacity: 0.42;
  z-index: 0;
  border-radius: 70px;
}

.gradient-box > * {
  position: relative;
  z-index: 1;
}

.hide-scrollbar {
  -ms-overflow-style: none;  /* Internet Explorer and Edge */
  scrollbar-width: none;     /* Firefox */
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;            /* Chrome, Safari, Opera */
}