@import url('https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
  :root {
    --background: 252 94% 91%; /* Converted from #FCF1E7 */
    --foreground: 222 15% 13%; /* Converted from #222222 */

    --muted: 30 26% 88%; /* Converted from #F4DECB */
    --muted-foreground: 220 3% 42%; /* Converted from #6C6C6C */

    --popover: 252 94% 91%; /* Converted from #FCF1E7 */
    --popover-foreground: 222 15% 13%; /* Converted from #222222 */

    --border: 220 3% 42%; /* Converted from #6C6C6C */
    --input: 220 3% 42%; /* Same as border for consistency */

    --card: 18 47% 92%; /* Converted from #F8D3C4 */
    --card-foreground: 222 15% 13%; /* Converted from #222222 */

    --primary: 10 70% 75%; /* Converted from #F0B094 */
    --primary-foreground: 13 69% 58%; /* Converted from #E38056 */

    --secondary: 28 45% 19%; /* Converted from #5C3411 */
    --secondary-foreground: 0 0% 100%; /* White for contrast */

    --accent: 28 45% 36%; /* Converted from #A26632 */
    --accent-foreground: 0 0% 100%; /* White for contrast */

    --destructive: 30 26% 86%; /* Converted from #DCB08A */
    --destructive-foreground: 0 0% 100%; /* White for contrast */

    --ring: 220 3% 42%; /* Same as grey for border */
    --radius: 0.5rem;
    img {
      max-width: none; /* or set to your preferred value */
    }
  }

  .dark {
    --background: 222 15% 13%; /* Dark background */
    --foreground: 0 0% 100%; /* White foreground */

    --muted: 10 15% 15%; /* Dark muted */
    --muted-foreground: 0 0% 100%; /* White foreground for muted */

    --accent: 10 15% 10%; /* Darker accent */
    --accent-foreground: 0 0% 100%; /* White */

    --popover: 222 15% 13%; /* Dark popover background */
    --popover-foreground: 0 0% 100%; /* White */

    --border: 10 15% 15%; /* Dark border */
    --input: 10 15% 15%; /* Dark input */

    --card: 222 15% 13%; /* Darker card */
    --card-foreground: 0 0% 100%; /* White text */

    --primary: 10 70% 75%; /* Primary pink */
    --primary-foreground: 0 0% 100%; /* White */

    --secondary: 28 45% 19%; /* Darker secondary */
    --secondary-foreground: 0 0% 100%; /* White */

    --destructive: 0 63% 31%; /* Dark red */
    --destructive-foreground: 0 0% 100%; /* White */

    --ring: 222 15% 13%; /* Dark ring */
    --radius: 0.5rem;
  }

  html, body {
    width: 100vw;
    max-width: 100%;
    @apply bg-background text-foreground;
    font-family: 'Urbanist', sans-serif;
    overflow-x: hidden; /* Prevent horizontal scrolling */
    margin: 0; /* Optional: Remove default margin */
  }
}

@keyframes shiny {
  0% {
    background-position: -200%;
  }
  100% {
    background-position: 200%;
  }
}

.animate-shiny-text {
  animation: shiny 1.3s linear infinite;
}

img {
  max-width: 100%; /* Ensures images stay within their parent container */
  height: auto; /* Maintains aspect ratio */
}


